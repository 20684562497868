import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import Rating from "react-rating";

const ourExperts = [
  {
    name: "Sarah Johnson",
    picurl: "https://placehold.co/50",
    expertise: [
      "Computer Science",
      "Electrical Engineering",
      "Mechanical Engineering",
    ],
    review:
      "The assignment service was amazing! I received my work well before the deadline, and the quality was top-notch. Will definitely use this service again.",
    rating: 5,
    orders: 120,
  },
  {
    name: "John Smith",
    picurl: "https://placehold.co/50",
    expertise: ["Mathematics", "Statistics", "Physics"],
    review:
      "Fast and reliable service. The expert provided detailed solutions that helped me understand complex topics.",
    rating: 4.8,
    orders: 98,
  },
  {
    name: "Emily Davis",
    picurl: "https://placehold.co/50",
    expertise: ["Economics", "Finance", "Accounting"],
    review:
      "Great experience! My finance assignment was perfectly done. The expert explained everything clearly.",
    rating: 4.9,
    orders: 110,
  },
  {
    name: "Michael Brown",
    picurl: "https://placehold.co/50",
    expertise: ["Biology", "Chemistry", "Environmental Science"],
    review:
      "The biology expert did a fantastic job on my assignment, and I learned a lot from their explanations.",
    rating: 4.7,
    orders: 87,
  },
  {
    name: "Jessica Wilson",
    picurl: "https://placehold.co/50",
    expertise: ["Law", "Political Science", "Sociology"],
    review:
      "Very impressed with the detailed feedback and thorough research. Will recommend to my peers.",
    rating: 4.9,
    orders: 105,
  },
  {
    name: "David Martinez",
    picurl: "https://placehold.co/50",
    expertise: ["Psychology", "Philosophy", "History"],
    review:
      "Fantastic service! The expert helped me tackle a difficult psychology topic with clear explanations.",
    rating: 4.6,
    orders: 92,
  },
  {
    name: "Sophia Garcia",
    picurl: "https://placehold.co/50",
    expertise: ["Marketing", "Business Administration", "Management"],
    review:
      "Extremely satisfied with the assignment! I received valuable insights that helped me improve my project.",
    rating: 4.8,
    orders: 115,
  },
  {
    name: "William Anderson",
    picurl: "https://placehold.co/50",
    expertise: ["Civil Engineering", "Architecture", "Urban Planning"],
    review:
      "Amazing service! The expert was highly professional, and the work exceeded my expectations.",
    rating: 4.9,
    orders: 102,
  },
  {
    name: "Olivia Lee",
    picurl: "https://placehold.co/50",
    expertise: ["Nursing", "Healthcare Management", "Medicine"],
    review:
      "The expert delivered a well-researched and professionally written nursing assignment. Highly recommended!",
    rating: 4.7,
    orders: 97,
  },
  {
    name: "James Taylor",
    picurl: "https://placehold.co/50",
    expertise: [
      "Information Technology",
      "Cybersecurity",
      "Software Engineering",
    ],
    review:
      "My IT project was completed on time and with excellent quality. The expert was knowledgeable and helpful.",
    rating: 4.8,
    orders: 108,
  },
];

export function HomeExperts() {
  const [slidesPerView, setSlidesPerView] = useState(3);

  useEffect(() => {
    function updateSlidesPerView() {
      if (window.innerWidth < 700) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 1000) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(3);
      }
    }
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  return (
    <div className="home__sale overflow-hidden">
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
        className="home__industry__heading"
      >
        Meet our top <span>talents</span>
      </div>
      <Swiper
        modules={[Navigation]}
        slidesPerView={slidesPerView}
        spaceBetween={30}
        loop={true}
        navigation={{
          nextEl: ".next",
          prevEl: ".prev",
        }}
        className="mySwiper"
      >
        {ourExperts.map((review, idx) => (
          <SwiperSlide key={idx}>
            <ExpertsEntry item={review} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="home__sale__navigation__btns">
        <button className="prev">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-arrow-left"
          >
            <path d="m12 19-7-7 7-7" />
            <path d="M19 12H5" />
          </svg>
        </button>
        <button className="next">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-arrow-right"
          >
            <path d="M5 12h14" />
            <path d="m12 5 7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  );
}

function ExpertsEntry({ item }) {
  return (
    <div className="home__expert__entry">
      <div className="home__expert__entry__info">
        <div className="home__expert__entry__info__pic">
          <img src={item.picurl} alt={item.name} />
        </div>
        <div className="home__expert__entry__info__text">
          <div className="home__expert__entry__info__text__name">
            {item.name}
          </div>
          <span className="home__expert__entry__info__text__orders">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-package"
            >
              <path d="M11 21.73a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73z" />
              <path d="M12 22V12" />
              <path d="m3.3 7 7.703 4.734a2 2 0 0 0 1.994 0L20.7 7" />
              <path d="m7.5 4.27 9 5.15" />
            </svg>{" "}
            {item.orders} orders
          </span>
          <span className="home__expert__entry__info__text__rating">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="currentColor"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-star"
            >
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
            </svg>{" "}
            {item.rating}
          </span>
        </div>
      </div>
      <div className="home__expert__entry__expertise">
        <span>Proficient at:</span>{" "}
        {item.expertise.map((expertise) => (
          <span
            className="home__expert__entry__expertise__item"
            key={expertise}
          >
            {expertise}
          </span>
        ))}
      </div>
      <div className="home__expert__entry__review__heading">Recent Review</div>
      <div className="home__expert__entry__review">{item.review}</div>

      <button className="home__expert__entry__review__btn">Hire Expert</button>
    </div>
  );
}
