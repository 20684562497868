import React from "react";
import { HomeCareSection } from "../components/HomeCareSection";
import { HomeJumbotronSection } from "../components/HomeJumbotronSection";
import { HomePaymentsSection } from "../components/HomePaymentsSection";
import { HomeSaleSection } from "../components/HomeSaleSection";
import { HomeSolutionsSection } from "../components/HomeSolutionsSection";
import { HomeJoinTeamSection } from "../components/HomeJoinTeamSection";
import { HomeFaqSection } from "../components/HomeFaqSection";
import { HomeExperts } from "../components/HomeExpertsSection";
import HowitWorks from "../components/HowitWorks";

export default function Home() {
  return (
    <>
      <HomeJumbotronSection />
      <HomeExperts />
      {/* <HomeSolutionsSection /> */}
      <HomePaymentsSection />
      <HomeCareSection />
      <HowitWorks />
      <HomeSaleSection />
      {/* <HomeJoinTeamSection /> */}
      <HomeFaqSection />
    </>
  );
}
