import React, { useCallback, useState } from "react";
import { homejumbotron } from "../assets";
import { Fade } from "react-reveal";
import OutsideClickHandler from "react-outside-click-handler";
import DateRange from "./DateRange";

const options = [
  { id: "mxfk", type_of_paper: "Essay" },
  { id: "ltvo", type_of_paper: "Research paper" },
  { id: "k9ba", type_of_paper: "Course work" },
  { id: "n6yw", type_of_paper: "Question & Answer" },
  { id: "30ky", type_of_paper: "PowerPoint Presentation" },
  { id: "ktko", type_of_paper: "Case study" },
  { id: "61xs", type_of_paper: "Discussion board post" },
  { id: "plu6", type_of_paper: "Literature review" },
  { id: "genk", type_of_paper: "Report" },
  { id: "jtm0", type_of_paper: "Critical thinking" },
  { id: "hqzq", type_of_paper: "Article review" },
  { id: "eds5", type_of_paper: "Reflective report" },
  { id: "k0ve", type_of_paper: "Annotated bibliography" },
  { id: "nvwg", type_of_paper: "Research proposal" },
  { id: "noo9", type_of_paper: "Argumentative essay" },
  { id: "i47m", type_of_paper: "Term paper" },
  { id: "awho", type_of_paper: "Capstone project" },
  { id: "si8j", type_of_paper: "Dissertation" },
  { id: "wb1i", type_of_paper: "Creative writing" },
  { id: "3l92", type_of_paper: "Personal statement" },
  { id: "njbn", type_of_paper: "Business plan" },
  { id: "u44a", type_of_paper: "Book review" },
  { id: "5eu4", type_of_paper: "Presentation" },
  { id: "x7b4", type_of_paper: "Movie review" },
  { id: "brm0", type_of_paper: "Thesis" },
  { id: "be07", type_of_paper: "Speech" },
  { id: "007b", type_of_paper: "Admission essay" },
  { id: "kwz3", type_of_paper: "Poster presentation" },
  { id: "bok5", type_of_paper: "Dissertation chapter" },
  { id: "nmv4", type_of_paper: "Article" },
  { id: "04q1", type_of_paper: "Blog writing" },
  { id: "aetp", type_of_paper: "Dissertation proposal" },
  { id: "m2d3", type_of_paper: "Thesis proposal" },
  { id: "xbzc", type_of_paper: "Thesis statement" },
  { id: "aune", type_of_paper: "Resume" },
  { id: "7v0l", type_of_paper: "Rewriting" },
  { id: "pmxc", type_of_paper: "Online Lecture to Summary" },
  { id: "z5oc", type_of_paper: "Dissertation results" },
  { id: "2w5z", type_of_paper: "Dissertation methodology" },
  { id: "dqos", type_of_paper: "Exam notes" },
  { id: "uu1m", type_of_paper: "Biography" },
  { id: "vmas", type_of_paper: "Cover letter" },
  { id: "pnfh", type_of_paper: "Dissertation introduction" },
  { id: "uovm", type_of_paper: "Multiple choice questions" },
  { id: "o4mk", type_of_paper: "Dissertation conclusion" },
  { id: "7a6a", type_of_paper: "Dissertation topics" },
  { id: "x6gi", type_of_paper: "Dissertation abstract" },
  { id: "okxn", type_of_paper: "Typing" },
  { id: "q9q2", type_of_paper: "Application letter" },
  { id: "duxm", type_of_paper: "Curriculum vitae" },
  { id: "9x9n", type_of_paper: "Dissertation hypothesis" },
  { id: "szvz", type_of_paper: "Other" },
];

export function HomeJumbotronSection() {
  const [showTypeDialog, setShowTypeDialog] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [searchType, setSearchType] = useState("");
  const [showDateRangeDialog, setShowDateRangeDialog] = useState(false);

  const filteredOptions = options.filter((option) =>
    option.type_of_paper.toLowerCase().includes(searchType.toLowerCase().trim())
  );
  const handleSelectType = useCallback((selected) => {
    setSelectedType(selected);
    setShowTypeDialog(false);
  }, []);
  return (
    <div className="home__jumbotron">
      <img
        src={homejumbotron}
        alt="homejumbotron"
        className="home__jumbotron__img"
      />
      <div className="home__jumbotron__overlay">
        <div className="home__jumbotron__overlay__content">
          <div className="home__jumbotron__overlay__content__heading">
            <Fade>Best Assignment Help You Can Get Online</Fade>
          </div>
          <div className="home__jumbotron__overlay__content__info">
            <Fade>
              Comprehensive Assignment Assistance With a No-AI Guarantee!
            </Fade>
          </div>
          <div className="home__jumbotron__overlay__content__form">
            <button
              onClick={() => setShowTypeDialog(!showTypeDialog)}
              className="home__jumbotron__overlay__content__form__type"
            >
              {selectedType ? (
                <span>{selectedType}</span>
              ) : (
                <span>Select Type</span>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-chevron-down"
                style={{
                  transform: showTypeDialog ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              >
                <path d="m6 9 6 6 6-6" />
              </svg>
            </button>
            <button
              onClick={() => setShowDateRangeDialog(!showDateRangeDialog)}
              className="home__jumbotron__overlay__content__form__timeline"
            >
              9:01 PM, Oct 30
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-calendar-clock"
              >
                <path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5" />
                <path d="M16 2v4" />
                <path d="M8 2v4" />
                <path d="M3 10h5" />
                <path d="M17.5 17.5 16 16.3V14" />
                <circle cx="16" cy="16" r="6" />
              </svg>
            </button>
            <button className="home__jumbotron__overlay__content__form__cta">
              Place an Order
            </button>
            <OutsideClickHandler
              onOutsideClick={() => setShowTypeDialog(false)}
            >
              {showTypeDialog && (
                <div className="home__jumbotron__overlay__content__form__dialog">
                  <div className="home__jumbotron__overlay__content__form__dialog__search">
                    <input
                      type="search"
                      placeholder="Search"
                      className="home__jumbotron__overlay__content__form__dialog__search__input"
                      onChange={(e) => setSearchType(e.target.value)}
                      value={searchType}
                    />
                  </div>
                  <ul className="home__jumbotron__overlay__content__form__dialog__list">
                    {filteredOptions.map((option) => (
                      <li
                        key={JSON.stringify(option)}
                        className="home__jumbotron__overlay__content__form__dialog__list__item"
                      >
                        <button
                          onClick={() => handleSelectType(option.type_of_paper)}
                          className="home__jumbotron__overlay__content__form__dialog__list__item__button"
                        >
                          {option.type_of_paper}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </OutsideClickHandler>
            <OutsideClickHandler
              onOutsideClick={() => setShowDateRangeDialog(false)}
            >
              {showDateRangeDialog && (
                <div className="home__jumbotron__overlay__content__form__dialog date__range">
                  <DateRange />
                </div>
              )}
            </OutsideClickHandler>
          </div>
        </div>
      </div>
    </div>
  );
}
