import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";

const DateRange = () => {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelect = (ranges) => {
    console.log(ranges);
    setSelectionRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection",
    });
  };

  return (
    <DateRangePicker
      ranges={[selectionRange]}
      onChange={handleSelect}
      rangeColors={["#3cb149"]}
      color="#3cb149"
      minDate={new Date()}
      showMonthAndYearPickers={false}
      editableDateInputs={true}
      moveRangeOnFirstSelection={false}
    />
  );
};

export default DateRange;
