import React, { useEffect, useRef, useState } from "react";

const images = {
  step1: "https://prepmypaper.com/wp-content/uploads/2024/02/hiw-1.png",
  step2: "https://prepmypaper.com/wp-content/uploads/2023/09/step-second.png",
  step3: "https://prepmypaper.com/wp-content/uploads/2023/09/step-3-1.png",
  step4: "https://prepmypaper.com/wp-content/uploads/2023/09/step-4.png",
};

export default function HowitWorks() {
  const [activeTab, setActiveTab] = useState("step1");
  return (
    <div className="howitworks">
      <div className="howitworks__content">
        <div className="home__care__header__heading">
          Get it done in 4 steps
        </div>
        <div className="howitworks__content__content">
          <div className="howitworks__content__content__left">
            <img src={images[activeTab]} alt="Steps to get started" />
          </div>
          <div className="howitworks__content__content__right">
            <div className="howitworks__content__content__right__tabs">
              <button
                onClick={() => setActiveTab("step1")}
                className={`howitworks__content__content__right__tabs__tab ${
                  activeTab === "step1" ? "active" : ""
                } `}
              >
                <div className="howitworks__content__content__right__tabs__tab__step">
                  step 1
                </div>
                <div className="howitworks__content__content__right__tabs__tab__heading">
                  Place an order{" "}
                </div>
                <div className="howitworks__content__content__right__tabs__tab__info">
                  Place an order and precisely describe what needs to be done
                  with your task. This way, we’ll be able to deliver the best
                  results.
                </div>
              </button>

              <button
                onClick={() => setActiveTab("step2")}
                className={`howitworks__content__content__right__tabs__tab ${
                  activeTab === "step2" ? "active" : ""
                } `}
              >
                <div className="howitworks__content__content__right__tabs__tab__step">
                  step 2
                </div>
                <div className="howitworks__content__content__right__tabs__tab__heading">
                  Make a deposit{" "}
                </div>
                <div className="howitworks__content__content__right__tabs__tab__info">
                  Make the deposit using one of the available payment methods.
                </div>
              </button>

              <button
                onClick={() => setActiveTab("step3")}
                className={`howitworks__content__content__right__tabs__tab ${
                  activeTab === "step3" ? "active" : ""
                } `}
              >
                <div className="howitworks__content__content__right__tabs__tab__step">
                  step 3
                </div>
                <div className="howitworks__content__content__right__tabs__tab__heading">
                  Get an expert assigned
                </div>
                <div className="howitworks__content__content__right__tabs__tab__info">
                  Once it’s done, our system will match you with the best
                  available proofreader.
                </div>
              </button>
              <button
                onClick={() => setActiveTab("step4")}
                className={`howitworks__content__content__right__tabs__tab ${
                  activeTab === "step4" ? "active" : ""
                } `}
              >
                <div className="howitworks__content__content__right__tabs__tab__step">
                  step 4
                </div>
                <div className="howitworks__content__content__right__tabs__tab__heading">
                  Verify your order
                </div>
                <div className="howitworks__content__content__right__tabs__tab__info">
                  After they finish working on your task, check it to ensure it
                  meets the provided requirements.
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
