export const faqData = [
  {
    heading: "Do you utilize GPT or any other AI tools?",
    info: "Absolutely not. We prioritize human expertise above all else. Should our experts attempt to use AI tools, they will be immediately terminated.",
  },
  {
    heading: "Can I communicate with the expert assigned to my order?",
    info: "Certainly! Within your personal dashboard, you’ll find a message section under your order. There, you can communicate directly with either a support agent or the expert assigned to your order.",
  },
  {
    heading:
      "If the quality falls short of my expectations, what steps are taken?",
    info: "If so, you may request 3 free revisions of your order within 7 days.",
  },
  {
    heading: "What can you help me with?",
    info: "We can assist you with more than 60 subjects; all you have to do is fill out the order form and find yours. If you haven’t found your subject in the list, simply choose ‘other’ and specify it in the instructions.",
  },
  {
    heading: "How do I choose the right expert? ",
    info: "Trust our algorithm. It will pair you with the top expert according to your needs and requirements.",
  },
  {
    heading: "How do I know when the expert has finished working on my order? ",
    info: "You’ll receive an email and, if provided, a phone alert when your order is ready. Then, you can review and download it in doc, docs, ppt, pptx, csv, or any specified format per your order details.",
  },
];
